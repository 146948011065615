import React, { createContext, useState } from "react";

export const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
    const [searchResults, setSearchResults] = useState([]);
    const [sugestedProducts, setSugestedProducts] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [loading, setLoading] = useState(true);

    return (
        <GlobalStateContext.Provider
            value={{
                searchResults,
                setSearchResults,
                sugestedProducts,
                setSugestedProducts,
                productsList,
                setProductsList,
                loading,
                setLoading
            }}
        >
            {children}
        </GlobalStateContext.Provider>
    );
};