import React, { useEffect, useState, useContext } from 'react';
import {useNavigate} from "react-router-dom";
import {MONTH_NAMES} from "../../constants";
import Banner from "../../components/Banner";
import sugestedProductsService from '../../services/sugested-products';
import { GlobalStateContext } from "../../context/GlobalStateContext";

const ConnexityProducts = () => {
    const [date, setDate] = useState('');
    const navigate = useNavigate();
    const { searchResults, setSearchResults, sugestedProducts, setSugestedProducts, productsList, setProductsList, loading, setLoading } = useContext(GlobalStateContext);
    
    useEffect(() => {
        const today = new Date();
        const currentMonth = MONTH_NAMES[today.getMonth()];
        const currentDay = today.getDate();
        setDate(`${currentMonth} ${currentDay}`);
    }, []);

    useEffect(() => {
        if(searchResults.length > 0) {
            navigate(`/`, { state: { items: searchResults} });
        }

    }, [searchResults, sugestedProducts])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await sugestedProductsService();
                const result = response.data;
                setSugestedProducts(result); 
            } catch (err) {
                console.error(err.message);
            } finally {
                setLoading(false);
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    return (
        <main className="main-wrapper">
            <Banner 
                titleText={`Hottest Deals For: ${date}`} 
                setSearchResults={setSearchResults} 
                setSugestedProducts={setSugestedProducts}
                searchResults={searchResults} 
                sugestedProducts={sugestedProducts} 
                setProductsList={setProductsList} 
                setLoading={setLoading} />
            <div className="axil-main-slider-area about-main-slider-area main-slider-style-7" style={{backgroundImage: 'url(/images/banner/banner-bg.webp)'}}>
            </div>
            {!loading ?
                <>
                <div className="axil-breadcrumb-area about-breadcrumb-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="inner">
                                <h1 className="about-subtitle">Best products you may like</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="axil-shop-area axil-section-gap bg-color-white main-container">
                    <div id='main' className="container"></div>
                    <div className="row row--15 products-inner-container">
                        {
                            sugestedProducts.length > 0 && sugestedProducts.map((el, i) => {
                                return (
                                    <div className="col-xl-3 col-lg-4 col-sm-6" key={i}>
                                        <div className="sugested-brands">
                                            <a href={el.link} rel="noreferrer" target="_blank">
                                                <img src={el.image} alt="" />
                                            </a>
                                            <a href={el.link} rel="noreferrer" target="_blank">{el.title}</a>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </>
            :
            <div className="loader-container">
                <span className="loader"></span>
            </div>
        }
        </main>
    )
}

export default ConnexityProducts;
