
import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import searchBrandsService from '../../services/search-brands';
import searchProductsService from '../../services/search-products';
import sugestedProductsService from '../../services/sugested-products';
import { GlobalStateContext } from "../../context/GlobalStateContext";

const Banner = ({titleText}) => {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [ searchValue, setSearchValue ] = useState('');
    const { setSearchResults, setSugestedProducts, setProductsList, setLoading } = useContext(GlobalStateContext);
    
    const submitSearch = async (e) => {
        e.preventDefault(e);

        if(searchValue !== '') {

            setSugestedProducts([]);
            setSearchResults([]);
            setProductsList([]);
            setLoading(true);
            
            try {
                const provider = 'connexity';
                const params = `brand=${searchValue}`;

                const brands = await searchBrandsService(provider, params);
                const result = brands.data;

                if(result.brands.length !== 0) {
                    
                    const brandIdParams = `brand=${result.brands[0].id}`;
                    
                    const products = await searchProductsService(provider, brandIdParams);
                    const productsResult = products.data;
                    
                    if(productsResult.products.length !== 0) {
                        setSearchResults(productsResult.products);
                    } else {
                        const response = await sugestedProductsService();
                        const result = response.data;
                        setSugestedProducts(result);
                    }
                } else {
                    const params = `search=${searchValue}`;

                    const products = await searchProductsService(provider, params);
                    if(products.data.products.length > 0) {
                        setSearchResults(products.data.products);
                    } else {
                        const response = await sugestedProductsService();
                        const result = response.data;
                        setSugestedProducts(result);
                    }
                } 
            } catch (error) {
                setLoading(false);
            }

            setLoading(false);
        }
    }

    const supportsWebP = (() => {
        const elem = document.createElement('canvas');
        return elem.toDataURL('image/webp').startsWith('data:image/webp');
    })();

    return (
        <div className="axil-main-slider-area main-slider-style-7 main-banner" style={supportsWebP ? {backgroundImage: 'url(/images/banner/banner-bg.webp)'} : {backgroundImage: 'url(/images/banner/banner-bg.png)'}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="main-slider-content">
                        <h1 className="title">{titleText}</h1>
                        <div className="shop-btn">
                            <a href="#main" className="axil-btn btn-bg-secondary right-icon">SHOP NOW</a>
                        </div>
                        <div className="search-form">
                            <form onSubmit={submitSearch}>
                                <input type="text" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="Search for a brand" />
                                <button type="submit"><i className="fas fa-search"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner;