import React, {useState, useEffect} from "react";
import {Link, useLocation, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    setHeaderCurrentCategory,
    setSelectedCategory,
    setCurrentCategory,
    setCategoryName
} from "../../redux/dataUploader/dataSlice";
import {PAGES} from "../../constants";

function HeaderCategoriesDropdown({data, removeSideMenu}) {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const allCategories = useSelector(state => state.dataUploader.allCategories);
    const mainCategories = useSelector(state => state.dataUploader.mainCategories);
    const [isSubPage, setSubPage] = useState(false);
    const [queryParameters] = useSearchParams();

    useEffect(() => {

        if(PAGES.includes(pathname)) setSubPage(true);
    }, [pathname]);

    const openMobileMenu = e => {
        if(window.innerWidth <= 992) {
            e.preventDefault();
            const targetParent = e.target.closest('.header-main-nav');
            const target = e.target.nextElementSibling;
            if (targetParent.classList.contains('open')) {
                if (target.style.display === 'block') {
                    target.style.display = 'none';
                } else {
                    target.style.display = 'block';
                }

                const parentItem = e.target.parentElement;
                parentItem.classList.toggle('open');
            }
        }
    }

    const onClickSelectCategory = (e, provider, categoryId, parentId = null) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setCategoryName(e.target.innerText));
        const {path} = allCategories.find(el => +el.id === +parentId)
        const updateProducts = window.location.pathname === path
        dispatch(setHeaderCurrentCategory({provider: provider, id: categoryId, updateProducts: updateProducts}))
        dispatch(setSelectedCategory(null))
        if(parentId) {
            dispatch(setCurrentCategory(allCategories.find(itm => itm.id === parentId)))
        } else {
            dispatch(setCurrentCategory(allCategories.find(itm => itm.id === categoryId)))
        }
        if(window.innerWidth <= 992) {
            const targetParent = e.target.closest('.header-main-nav');
            const target = e.target.closest('.axil-submenu');
            if (targetParent.classList.contains('open')) {
                targetParent.classList.remove('open')
                target.style.display = 'none';
                removeSideMenu(e);
            }
        }
        if(!updateProducts) {
            localStorage.setItem('categoryId', categoryId);
            localStorage.setItem('categoryProvider', provider);
            localStorage.setItem('categoryName', e.target.innerText);
            const queryParams = []
            if (queryParameters.get('gclid')) {
                queryParams.push('gclid=' + queryParameters.get('gclid'))
            }
            window.open(
                process.env.REACT_APP_API_URL + path + (queryParams.length > 0 ? ('?' + queryParams.join('&')) : ''),
                "_self"
            )

        }
    }

    const removeTitle = () => {
        localStorage.removeItem('categoryName');
        localStorage.removeItem('categoryId');
        localStorage.removeItem('categoryProvider');
    }

    return (
        <li className="menu-item-has-children">
            <button className={window.innerWidth <= 992 ? 'mobile-header-btn' : null} onClick={e => openMobileMenu(e)}>
                Category
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" height="12" width="10.5" viewBox="0 0 448 512">
                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/>
                    </svg>
                </span>
            </button>
            <ul className="axil-submenu">
                {
                    data.length > 0 && data.map((el, index) => {
                        const {provider, id, name, categories, path} = el;
                        const parentId = id;
                        const queryParams = []
                        if (queryParameters.get('gclid')) {
                            queryParams.push('gclid=' + queryParameters.get('gclid'))
                        }
                        return (
                            categories && categories.length > 0 ?
                                <li className={window.innerWidth <= 992 ? 'menu-item-has-children mob' : 'menu-item-children'} key={index}>
                                    <a href={process.env.REACT_APP_API_URL + path + (queryParams.length > 0 ? ('?' + queryParams.join('&')) : '')} id={id} onClick={removeTitle}>
                                        {name}
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="12" width="7.5" viewBox="0 0 320 512">
                                                <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/>
                                            </svg>
                                        </span>
                                    </a>
                                    <ul className="children-menu-item">
                                        {categories.map((el, index) => {
                                            const {id, name, count} = el;
                                            if (count) {
                                                if (count > 0) {
                                                    return <li key={id}>
                                                        <button
                                                            id={id}
                                                            data-id={index}
                                                            onClick={e => onClickSelectCategory(e, provider, id, parentId)}>
                                                            {name}
                                                        </button>
                                                    </li>
                                                } else {
                                                    return null;
                                                }
                                            } else {
                                                return <li key={id}>
                                                    <button
                                                        id={id}
                                                        data-id={index}
                                                        onClick={e => onClickSelectCategory(e, provider, id, parentId)}>
                                                        {name}
                                                    </button>
                                                </li>
                                            }
                                        })}
                                    </ul>
                                </li> :
                                categories && categories.length === 0 ?
                                    <li onClick={e => onClickSelectCategory(e, provider, e.target.id, parentId)} key={index}>
                                        {isSubPage ?
                                            <Link
                                                to={mainCategories.filter(el => +el.id === +id)[0]['path'] ? mainCategories.filter(el => +el.id === +id)[0]['path'] : '#'}
                                                id={id}>{name}</Link> :
                                            <button id={id}>{name}</button>
                                        }
                                    </li> :
                                    <li className="menu-item" key={index}><button id={id}>{name}</button></li>
                        )
                    })
                }

                <li className={window.innerWidth <= 992 ? 'mob' : 'menu-item-children'} key={`00001`}>
                    <a href={process.env.REACT_APP_API_URL + `/lpv/connexity-related-products/`} id={`00001`} onClick={removeTitle}>
                        Connexity products
                    </a>
                </li>
                
                {data.length === 0 && (
                    <div className="categories-popup-loader-container">
                        <span className="loader"></span>
                    </div>
                )}
            </ul>
        </li>
    );
}

export default HeaderCategoriesDropdown;
