import Home from "../pages/Home";
import About from "../pages/About";
import Legal from "../pages/Legal";
import ConnexityProducts from "../pages/ConnexityProducts";
import {LEGAL_PAGES} from "../constants";

const routeConfig =  [
    {
        path: '*',
        element: <Home/>,
    },
    {
        path: '/lpv/connexity-related-products/',
        element: <ConnexityProducts/>,
    },
    {
        path: '/about/',
        element: <About/>,
    },
    {
        path: `/${LEGAL_PAGES.PRIVACY.link}/`,
        element: <Legal typeObj={LEGAL_PAGES.PRIVACY}/>,
    },
    {
        path: `/${LEGAL_PAGES.TERMS.link}/`,
        element: <Legal typeObj={LEGAL_PAGES.TERMS}/>,
    },
    {
        path: `/${LEGAL_PAGES.COOKIE.link}/`,
        element: <Legal typeObj={LEGAL_PAGES.COOKIE}/>,
    }
]

export default routeConfig;
