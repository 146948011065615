import React from "react";
import {Routes, Route} from "react-router-dom";
import routeConfig from "./index";

const MainRouter = ({categoriesList, categoriesError}) => {
    return (
        <Routes>
            <Route>
                {routeConfig.map(route =>
                    <Route
                        key={route.path}
                        path={route.path}
                        element={React.cloneElement(route.element, {categoriesList, categoriesError})}
                    />
                )}
            </Route>
        </Routes>
    )
}

export default MainRouter;